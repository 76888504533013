@font-face {
    font-family: "icons";
    src: url("./icons.woff2?b5392d4a2879d78671e28199f28b3758") format("woff2"),
url("./icons.woff?b5392d4a2879d78671e28199f28b3758") format("woff");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-zoom:before {
    content: "\f101";
}
.icon-wheel:before {
    content: "\f102";
}
.icon-trash:before {
    content: "\f103";
}
.icon-team:before {
    content: "\f104";
}
.icon-smartphone-car:before {
    content: "\f105";
}
.icon-slider-arrow:before {
    content: "\f106";
}
.icon-share:before {
    content: "\f107";
}
.icon-share-symbol:before {
    content: "\f108";
}
.icon-service:before {
    content: "\f109";
}
.icon-route:before {
    content: "\f10a";
}
.icon-placeholder:before {
    content: "\f10b";
}
.icon-phone:before {
    content: "\f10c";
}
.icon-offer:before {
    content: "\f10d";
}
.icon-nav-close:before {
    content: "\f10e";
}
.icon-move:before {
    content: "\f10f";
}
.icon-mail:before {
    content: "\f110";
}
.icon-logo:before {
    content: "\f111";
}
.icon-logo-with-text:before {
    content: "\f112";
}
.icon-location:before {
    content: "\f113";
}
.icon-info:before {
    content: "\f114";
}
.icon-info-black:before {
    content: "\f115";
}
.icon-image:before {
    content: "\f116";
}
.icon-hyundai-logo:before {
    content: "\f117";
}
.icon-globe:before {
    content: "\f118";
}
.icon-filter:before {
    content: "\f119";
}
.icon-download:before {
    content: "\f11a";
}
.icon-contact:before {
    content: "\f11b";
}
.icon-chevron-right:before {
    content: "\f11c";
}
.icon-chevron-left:before {
    content: "\f11d";
}
.icon-chevron-down:before {
    content: "\f11e";
}
.icon-check:before {
    content: "\f11f";
}
.icon-car:before {
    content: "\f120";
}
.icon-calender:before {
    content: "\f121";
}
.icon-burger:before {
    content: "\f122";
}
.icon-arrow-right:before {
    content: "\f123";
}
.icon-social-youtube:before {
    content: "\f124";
}
.icon-social-x:before {
    content: "\f125";
}
.icon-social-tiktok:before {
    content: "\f126";
}
.icon-social-linkedin:before {
    content: "\f127";
}
.icon-social-instagram:before {
    content: "\f128";
}
.icon-social-facebook:before {
    content: "\f129";
}
.icon-rte-toolbar-underline:before {
    content: "\f12a";
}
.icon-rte-toolbar-number-list:before {
    content: "\f12b";
}
.icon-rte-toolbar-link:before {
    content: "\f12c";
}
.icon-rte-toolbar-italic:before {
    content: "\f12d";
}
.icon-rte-toolbar-bullet-list:before {
    content: "\f12e";
}
.icon-rte-toolbar-bold:before {
    content: "\f12f";
}
.icon-rte-toolbar-align-right:before {
    content: "\f130";
}
.icon-rte-toolbar-align-left:before {
    content: "\f131";
}
.icon-rte-toolbar-align-center:before {
    content: "\f132";
}
.icon-pagination-chevron-right:before {
    content: "\f133";
}
.icon-pagination-chevron-right-double:before {
    content: "\f134";
}
.icon-pagination-chevron-left:before {
    content: "\f135";
}
.icon-pagination-chevron-left-double:before {
    content: "\f136";
}
.icon-fontawesome-warn:before {
    content: "\f137";
}
.icon-fontawesome-trash:before {
    content: "\f138";
}
.icon-fontawesome-edit:before {
    content: "\f139";
}
.icon-envkv-gas-station:before {
    content: "\f13a";
}
.icon-envkv-gas-station-battery:before {
    content: "\f13b";
}
.icon-envkv-eco-plant:before {
    content: "\f13c";
}
.icon-envkv-eco-plant-battery:before {
    content: "\f13d";
}
.icon-envkv-carbon:before {
    content: "\f13e";
}
.icon-envkv-battery:before {
    content: "\f13f";
}
